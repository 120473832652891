import React from 'react';
import { Helmet } from 'react-helmet';
import DJService from '../components/DJService';
import Layout from '../components/Layout';
import { graphql } from 'gatsby';
import ClientsBanner from '../components/ClientsBanner';

const SongsTemplate = ({ data }) => (
  <Layout>
    <Helmet>
      <title>Party Band Bristol, Live Bands for Hire Bath - Audio Vultures Repertoire</title>
      <meta
        name="description"
        content="View the song repertoire of Bristol party band Audio Vultures. One of the premier live bands for hire in Bath and Bristol, we have a great setlist covering all genres!"
      />
    </Helmet>
    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Our Party Band Setlist</h1>
          </header>

          <div className="row">
            <div className="12u">
              <p>
                As the premiere party band in the Bristol and Bath areas we have built up a song
                repertoire that spans many decades and is guaranteed to keep the floor filled all
                night long! From pop to indie via some true rock and roll classics, we know how to
                get your party started!
              </p>

              <h2>Live Bands for Hire in Bristol, Bath and beyond!</h2>

              <p>
                Energy, charisma and a great setlist have resulted in Audio Vultures becoming one of
                the top live bands for hire in the Bristol and Bath areas. From Parties to wedding,
                private functions and corporate events, we have experience in them all and work
                nationwide!
              </p>
              <p>
                Be sure to check out our{' '}
                <a
                  href="https://www.instagram.com/audiovultures/"
                  target="_blank"
                  rel="noopener noreferrer">
                  Instagram feed{' '}
                </a>
                to see our latest photos and event updates from across the country!
              </p>
              <p>
                Have a question? Simply contact us today to find out more info – we will be very
                happy to discuss things further!{' '}
              </p>
            </div>
          </div>
          <hr className="major" />
          <div className="row">
            <div className="12u 12u$(small)">
              <div className="table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th>Song</th>
                      <th>Artist</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.allGoogleSheetBandRow.edges.map((d) => (
                      <tr key={d.node.id}>
                        <td> {d.node.song}</td>
                        <td> {d.node.artist}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <hr className="major" />

          <div className="row">
            <div className="6u 12u$(small)">
              <h3>Late Finish?</h3>
              <p>
                If you wish to request a finish time after 12 midnight we will be happy to quote for
                this service. Just contact us today with your details and we can discuss your
                requirements further.
              </p>
              <hr className="major" />

              <h2>Follow Our Instagram</h2>
              <p>
                <span className="icon alt fa-instagram" />{' '}
                <a
                  href="https://www.instagram.com/audiovultures/"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer">
                  Follow us on Instagram
                </a>{' '}
                for more photos and videos!
              </p>
            </div>

            <div className="6u 12u$(small)">
              <h2>Audio</h2>
              <iframe
                width="100%"
                height="330"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                title="Audio Vultures Party Band"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/983807023&color=%23ff1919&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              />
            </div>
          </div>
        </div>
      </section>
      <hr className="major" />
      <DJService />
      <ClientsBanner />
    </div>
  </Layout>
);

export const query = graphql`
  query MyQuery {
    allGoogleSheetBandRow(sort: { fields: song, order: ASC }) {
      edges {
        node {
          id
          artist
          song
        }
      }
    }
  }
`;

export default SongsTemplate;
